import React from 'react';
import './Css/Divider.css';



const Divider = () => {
    return (
        <section id='Divider' className='Divider' >
  
  
   
        </section>
        
    );
};

export default Divider;
